<template>
    <div class="dialog" v-if="show">
        <div class="content">
            <p>
                本次兑奖服务由第三方公司提供，
                请提供您的手机号码用于第三方
                兑换奖品。
            </p>
            <div class="btn">
            <div><button @click="agree">同意</button></div>
            <div><button>不同意</button></div>
        </div>
        </div>
        
    </div>
</template>
<script>
export default {
    name:'Dialog',
    data() {
        return{
            show:true
        }
    },
    methods:{
        agree(){
            this.show = false
        }
    }
}
</script>
<style lang="scss" scoped>
    .dialog{
        background: rgba(0,0,0,0.3);
        position:fixed;
        top: 0;
        bottom: 0;
        z-index: 999;
        width: 100%;
        height: 100%;
        font-size: .2rem;
        display: flex;
        justify-content: center;
        align-items: center;
        .content{
            display: inline-block;
            width: 3.42rem;
            background: white;
            border-radius: 10px;
            padding: 0.1rem;
            text-align: left;
            .btn{
                margin-top: .5rem;
                display:flex;
                justify-content: space-around;
                margin-bottom: 0.2rem;
                button{
                background:#E84149;
                color: white;
                border-radius: 30px;
                // padding: 0.1rem 0.2rem;
                border: none;
                color:white;
                width: 1.28rem;
                }
            }
        }
       

    }
</style>

  
