<template>
  <div>
    <div class="home">
      <div class="head">
        <img src="../assets/banner.jpg" />
      </div>
      <div class="inner-input">
          <div><input type="text" v-model="code" placeholder="请输入兑换码"/></div>
          <div><button @click="check">查看兑换权益</button></div>
      </div>
      <div class="content">
        <div ><img src="../assets/head.png"/></div>
        <div class="process">
          <ul>
            <li v-for="(item,index) of process" :key="index">
              <div class="bot">0{{index+1}}</div>
              <div class="item">{{item}}</div>
            </li>
          </ul>
        </div>
        </div>
      <explain></explain>
    </div>
   <Dialog></Dialog>
  <Dialog2 v-show="showP" :code="goodscode"></Dialog2>
  </div>
</template>

<script>
import Dialog from '@/components/Dialog'
import Dialog2 from '@/components/Dialog2'
import explain from '@/components/explain'
import { checkcoupon } from '@/api'
export default {
  name: "Home",
  components:{
   Dialog,
    Dialog2,
   explain
  },
  data(){
    return{
      showP:false,
      code:'',
      content:'',
      process:[
        '输入兑换券码，查看可兑换的权益',
        '选择需要兑换的权益，点击"去兑换"',
        '根据具体页面提示输入相关信息，点击"立即兑换"',
        '兑换成功'
      ],
      explain:"",
      goodscode:"",
    }
  },
  methods:{
    async check(){
        if(this.code === "") return
        const result =  await checkcoupon({coupon:this.code})
        if(result.data.data.status){
          sessionStorage.setItem('counpon',result.data.data.coupon_info.counpon)
          if(result.data.data.coupon_info.is_used === 0){
              this.$router.replace("/list")
          }else{
              this.showP = true
              this.goodscode = result.data.data.coupon_info.goods_sn
          }
        }else{
          this.$toast(result.data.data.message)
        }
        
    }
  }
  
};
</script>
<style lang="scss" scoped>
  .home{
    font-size: .28rem;
    .head{
      width: 100%;
      height: 3.4rem;
      img{
        width: 100%;
        height: 100%;
      }
    }
    .inner-input{
        width: 6.26rem;
        margin: auto;
        margin-top: 0.5rem;
        margin-bottom:0.5rem;
      input{
        width: 5.86rem;
        height: .72rem;
        border:1px solid #707070;
        color: #999999;
        padding: 0 .2rem;
        border-radius: 5px;
      }
      button{
        @extend input;
        width: 6.26rem;
        margin: 0.2rem 0;
        background: #E84149;
        color: white;
        border-radius: 30px;
        border:none;
      }
    }
    .content{
      text-align: center;
      width: 6.92rem;
      padding: 0.1rem;
      border-radius:.1rem;
      background: white;
      margin: auto;
      img{
        width: 2.8rem;
        height: 0.35rem;
      }
      .process{
        ul{
          li{
            display: flex;
            justify-content: space-around;
            align-items: center;
            .bot{
              width: .56rem;
              height: .34rem;
              background: #E75046;
              color: white;
              font-size: .26rem;
              border-radius: 30px;
              margin: 0.12rem 0;
            }
            .item{
              text-align: left;
              width: 6.15rem;
            }
          }
        }
      }
    }
    
  }
    
</style>
