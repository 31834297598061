import axios from 'axios'
import qs from 'qs'


let isPro =  process.env.NODE_ENV === "production"
const PlatForm = "BCMCOUPON"



const service = axios.create({
    baseURL: isPro = isPro ? process.env.VUE_APP_API_BASE_URL : 'bcm/index',
    headers: {"PlatForm":PlatForm}
})

service.interceptors.request.use(
    config => {
        config.data = qs.stringify(config.data)

        return config
    },
    error => {
        console.log(error)
        return Promise.reject(error)
    }
)
export default service