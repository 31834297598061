<template>
  <div>
    <div class="explain">
      <div><img src="../assets/head2.png" /></div>
      <div>
        <p style="text-align: left">
          活动对象：广东省分行代发工资客户<br />
          1.活动期间内，客户可参与活动并获得视频影音会员兑换券,使用规则详见兑换页。<br />
          2.兑奖说明：活动总次数有限，秒完即止。奖品先兑先得。兑奖资格将在中奖后即时生效。为保证活动公平参与。若中奖后30日内未在收到奖励，
          最晚于2021年12月31日前反馈至我行，超过时效不予受理，视为放弃奖励。<br />
          3.如客户参与活动中发生任何虚假、欺诈、用于经营性目的、恶意分单等违法或不正常交易，
          或存在任何违反用卡约定的行为，或在出现可疑的非正常情况但拒绝配合交通银行进行调查核实，
          则交通银行保留对相关行为进行调查核实、拒绝相关人员参与本次活动、取消其获赠任何奖励的权利，并列入日后活动黑名单。<br />
          4.本次活动由客维智能科技（广州）有限公司提供兑换服务，可拨打客服电话4000-778-183享人工坐席服务咨询使用，
          咨询请说明交行-开薪狂欢活动。<br />
          5.本活动规则最终解释权归广东省分行所有。
          <br />
          <br />
        </p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {}
  },
}
</script>
<style lang="scss" scoped>
.explain {
  text-align: center;
  padding: 0.2rem;
  margin-top: 0.3rem;
  font-size: 0.24rem;
  color: #666666;
  letter-spacing: 0.2px;
  line-height: 0.4rem;
  img {
    width: 2.8rem;
    height: 0.35rem;
    text-align: center;
  }
}
</style>