var docEl = document.documentElement,
      setFontSize = function() {
        var clientWidth = document.documentElement.clientWidth
          // timer;
        if (!clientWidth) return; //原本对于html的字号设置 我这里用的是750的标准 ，640的也是同理
        docEl.style.fontSize = 100 * (clientWidth / 750) + "px";
        if (
          window.getComputedStyle(document.getElementsByTagName("html")[0])
            .fontSize
        ) {
          var size = window
            .getComputedStyle(document.getElementsByTagName("html")[0])
            .fontSize.split("p")[0]; //这里再取出当前html的font-size，和你想附的值进行比较， //为什么不直接*1.25 也不解释了 最终比例不一定是1.25
          //这里为什么不直接用size< 原因是正常机型的size也不一定等于你想附的值，尝试了很多机型，除了问题机型外，一般差距很小，另一半完全相等;
          if (size * 1.2 < 100 * (clientWidth / 750)) {
            //如果当前html的font-size 的1.2倍仍然小于 之前想设置的值，就说明是问题机型，给之前想附的值乘1.25倍，这样他会被系统再次除1.25得到的才是我们想附的值
            docEl.style.fontSize = 125 * (clientWidth / 750) + "px";
          }
        }


        var html = document.getElementsByTagName('html')[0];
        var settedFs,settingFs
         settedFs = settingFs = parseInt(html.style.fontSize);
        var whileCount = 0;
        while(whileCount >= 0) {
            var realFs = parseInt(window.getComputedStyle(html).fontSize);

            var delta = realFs - settedFs;
            if (Math.abs(delta) >= 1) //不相等
            {
                if (delta > 0) settingFs--; else settingFs++;
                html.setAttribute('style', 'font-size:'+settingFs + 'px !important');
            } else
                break;
            if (whileCount++ > 100) //之所以弄个100的循环跳出的原因，在于实在无法预判设备是否能计算得到36px，比如设备只能计算35px，37px，这样会死循环只能跳出了
                break
        }
      };

      window.addEventListener("resize",setFontSize)
      
    export default setFontSize