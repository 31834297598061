import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import rem from "@/utils/rem.js"
import { Toast } from 'vant';
import 'vant/lib/index.css';
Vue.use(Toast);
rem()

Vue.config.productionTip = false;

document.title = '开"薪"狂欢 百万豪礼'

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
