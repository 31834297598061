<template>
    <div class="dialog" v-if="show">
        <div class="content">
            <p>
                <span>您的兑换码为{{code}}</span>
                请注意保存并前往相关APP进行领取。
                    *我方保证券码有效使用时间最少为30天，
                超过30天券码未使用失效不作补偿，
                请及时使用。
            </p>
            <div class="btn">
                <button @click="agree">X</button>
            </div>
        </div>
        
    </div>
</template>
<script>
export default {
    name:'Dialog',
    props:[
        "code"
    ],
    data() {
        return{
            show:true,
            number:this.code,
        }
    },
    methods:{
        agree(){
            this.show = false
            this.$router.replace('/')
            this.$router.go()
        }
    },
    watch:{
        code(val){
            this.number = val
        }
    }
}
</script>
<style lang="scss" scoped>
    .dialog{
        background: rgba(0,0,0,0.3);
        position:fixed;
        top: 0;
        bottom: 0;
        z-index: 999;
        width: 100%;
        height: 100%;
        font-size: .16rem;
        display: flex;
        justify-content: center;
        align-items: center;
        .content{
            display: inline-block;
            width: 3.96rem;
            background: white;
            border-radius: 10px;
            padding: 0.5rem;
            text-align: left;
            position: relative;
            p{
                text-align: center;
                span{
                    display: block;
                }
            }
            .btn{
                position: absolute;
                top: 3%;
                right: 3%;
                button{
                    font-size: .3rem;
                    font-weight: 800;
                    border: none;
                    background: white;
                }
                }
            }
        }
       

    
</style>

  
