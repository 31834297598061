import request from '@/utils/request'

export function goodslist(){
    return request({
        url:'goods-list',
        method: 'get'
    })
}

export function goodsinfo(data){
    return request({
        url:'goods-info',
        method: 'get',
        params:data
    })
}

export function checkcoupon(data){
    return request({
        url:'check-coupon',
        method: 'post',
        data:data
    })
}

export function exchange(data){
    return request({
        url:'exchange',
        method: 'post',
        data:data
    })
}